.fnc-logo {
  svg {
    width: 140px;
    height: 73px;
  }

  @include respond-to(medium) {
    svg {
      width: 130px;
      height: 35px;
    }
  }

  @include respond-to(small) {
    svg {
      width: 105px;
      height: 31px;
    }
  }

  @include respond-to(xsmall) {
    svg {
      width: 90px;
      height: 28px;
    }
  }
}

.fnc-logo--small {
  width: 88px;
  height: 26px;
  display: inline-block;
  svg {
    width: 88px;
    height: 26px;
    display: block;
  }
}
