@mixin respond-to($media) {
  @if $media == xxsmall {
    @media screen and (max-width: $bp-xsmall) {
      @content;
    }
  } @else if $media == xsmall {
    @media screen and (max-width: $bp-small) {
      @content;
    }
  } @else if $media == xsmallup {
    @media screen and (min-width: $bp-xsmall) {
      @content;
    }
  } @else if $media == small {
    @media screen and (max-width: $bp-medium) {
      @content;
    }
  } @else if $media == smallup {
    @media screen and (min-width: $bp-small) {
      @content;
    }
  } @else if $media == landscapesmall {
    @media screen and (max-width: $bp-medium) and (orientation: landscape) {
      @content;
    }
  } @else if $media == medium {
    @media screen and (max-width: $bp-large) {
      @content;
    }
  } @else if $media == mediumup {
    @media screen and (min-width: $bp-medium) {
      @content;
    }
  } @else if $media == large {
    @media screen and (max-width: $bp-xlarge) {
      @content;
    }
  } @else if $media == largeup {
    @media screen and (min-width: $bp-large) {
      @content;
    }
  } @else if $media == xlarge {
    @media screen and (max-width: $bp-xxlarge) {
      @content;
    }
  } @else if $media == xlargeup {
    @media screen and (min-width: $bp-xlarge) {
      @content;
    }
  } @else if $media == xxlarge {
    @media screen and (max-width: $bp-xxlarge) {
      @content;
    }
  } @else if $media == xxlargeup {
    @media screen and (min-width: $bp-xxlarge) {
      @content;
    }
  } @else if $media == ie {
    @media screen and (min-width:0\0) {
      @content;
    }
  } @else {
    @content;
  }
}
