/**
 * Colors
 */

// Standard
$c-finc: #ffb81d;
$c-action: $c-finc;
$c-action-hover: darken($c-action, 10%);
$c-action-hover-focus: darken($c-action, 15%);
$c-action-text: $c-action;
$c-action-hover: darken($c-action-text, 10%);
$c-action-hover-focus: darken($c-action-text, 15%);

// Additionals
$c-text: #5b6770;
$c-text-dark: #1d1f2a;
$c-white: #fff;
$c-bg: $c-white;
$c-alabaster: #FAFAFB;
$c-black-haze: #F7F8FA;
$c-san-marino: #436CAA;

$c-bg-gradient: linear-gradient(135deg, #F8FCFF 0%, #EDF7FF 100%);
$c-bg-dark: #081E42;
$c-border: #E7EAEF;
$c-porcelain: #EFF4F2;
$c-iceberg: #C7DFDD;
$c-pale-taupe: #BD9284;
$c-gray: #A1A1A1;
$c-cinderella: #FBE3D9;
$c-vanhalla: #2D3142;
$c-bright-gray: #3C3F4F;
$c-oxley: #6F9A85;
$c-ziggurat: #C1E0DD;

// Success
$c-success: #40E68C;

// Warning
$c-warning: #dfb90f;

// Error
$c-error: #FA4343;
