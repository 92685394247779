.fnc-link-group {
  display: inline-flex;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 20px;
  }
}

.fnc-link-group--wrapped {
  flex-wrap: wrap;
  & > * {
    margin-bottom: 20px;
  }
}

.fnc-link-group--condensed {
  & > *:not(:last-child) {
    margin-right: 10px;
  }

  &.fnc-link-group--wrapped {
    & > * {
      margin-bottom: 10px;
    }
  }
}

.fnc-link-group--expanded {
  & > *:not(:last-child) {
    margin-right: 50px;

    @include respond-to(small) {
      margin-right: 30px;
    }
  }
}

.fnc-link-group--collapse {
  display: block;
  flex-direction: column;
  & > *:not(:last-child) {
    display: block;
    margin: 0;
    margin-bottom: 7px;
  }
}

.fnc-link-group--collapse-xs {
  @include respond-to(xsmall) {
    flex-direction: column;
    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 7px;
    }
    &.fnc-link-group--expanded {
      & > *:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }
}

.fnc-link-group--collapse-s {
  @include respond-to(small) {
    flex-direction: column;
    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 7px;
    }
    &.fnc-link-group--expanded {
      & > *:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }
}

.fnc-link-group--collapse-m {
  @include respond-to(medium) {
    flex-direction: column;
    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 7px;
    }
    &.fnc-link-group--expanded {
      & > *:not(:last-child) {
        margin-bottom: 25px;
      }
    }
  }
}
