$grid-gutter: 40px;
$grid-gutter-small: 25px;

$grid-settings: (
  config: (
    gridclass: "fnc-grid",
    itemclass: "fnc-col--",
    push: true,
    pushclass: "fnc-push--"
  ),
  grids: (
    default: (
      columns: 12,
      suffix: null,
      gutter: $grid-gutter
    ),
    xs: (
      columns: 12,
      suffix: "--xs",
      breakpoint_min: $bp-xsmall,
      gutter: $grid-gutter-small
    ),
    s: (
      columns: 12,
      suffix: "--s",
      breakpoint_min: $bp-small,
      gutter: $grid-gutter-small
    ),
    m: (
      columns: 12,
      suffix: "--m",
      breakpoint_min: $bp-medium,
      gutter: $grid-gutter
    ),
    l: (
      columns: 12,
      suffix: "--l",
      breakpoint_min: $bp-large,
      gutter: $grid-gutter
    ),
    xl: (
      columns: 12,
      suffix: "--xl",
      breakpoint_min: $bp-xlarge,
      gutter: $grid-gutter
    ),
    xxl: (
      columns: 12,
      suffix: "--xxl",
      breakpoint_min: $bp-xxlarge,
      gutter: $grid-gutter
    )
  )
);
