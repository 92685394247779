body {
  width: 100%;
  min-height: 100%;
  background: $c-bg;
  color: $c-text;
  font-weight: 500;
  @include font-smooth;

   // transfer breakpoints to JS
   &::before {
    display: none;
    content: "xlarge";

    @include respond-to(large) {
      content: "large";
    }

    @include respond-to(medium) {
      content: "medium";
    }

    @include respond-to(small) {
      content: "small";
    }

    @include respond-to(xsmall) {
      content: "xsmall";
    }
  }
}
