.fnc-table {
  display: block;
  overflow-x: auto;

  thead {
    th {
      border-bottom: 1px solid $c-border;
      text-align: left;
      font-weight: 700;
    }
  }

  tbody {
    th {
      position: relative;
      text-align: right;
      font-weight: 700;
    }
    tr {
      border-top: 1px solid $c-border;
      &:last-child {
        border-bottom: 1px solid $c-border;
      }
    }
  }

  th, td {
    padding: 30px 20px;
  }
}
