* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

html {
  font-family: $font-primary;
  font-size: 62.5%;
  min-height: 100%;
}

body {
  // sass-lint:disable-block no-vendor-prefixes
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  background: $c-bg;
  color: $c-text;
  font-size: 16px;
  line-height: 1.5;
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;
}
