.fnc-link {
  color: $c-action;
  font-size: $font-size;
  font-family: $font-heading;
  border-radius: $layout-border-radius;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  position: relative;

  @include respond-to(small) {
    font-size: $font-size--small;
  }
}

.fnc-link--active {
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    bottom: -8px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: $c-action;
  }
}

.fnc-link--small {
  font-size: $font-size--small;
}

.fnc-link--large {
  font-size: $font-size--large;
}

.fnc-link--block {
  display: block;
  width: 100%;
}

.fnc-link--alt {
  color: $c-text;

  .fnc-icon {
    background: none;
    color: $c-text;
  }
}

.fnc-link--underline {
  text-decoration: underline;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.fnc-link--icon-after {
  .fnc-icon {
    margin-left: 10px;
  }
}

.fnc-link--button {
  background: $c-porcelain;
  font-size: $font-size--small;
  padding: 7px 14px;
  border-radius: $layout-border-radius;
  color: $c-text;
  transition: background-color .1s, color .1s;

  &:hover, &:focus {
    background-color: $c-action;
    color: $c-white;
  }

  &:hover:focus {
    background-color: $c-action-hover;
  }
}
