// Show on breakpoint X
.fnc-u-visible {
  display: block;

  @include respond-to(large) {
    &--l {
      display: block !important;
    }
  }

  @include respond-to(medium) {
    &--m {
      display: block !important;
    }
  }

  @include respond-to(small) {
    &--s {
      display: block !important;
    }
  }

  @include respond-to(xsmall) {
    &--xs {
      display: block !important;
    }
  }
}

// Hidden on breakpoint X
.fnc-u-hidden {
  display: none;

  @include respond-to(large) {
    &--l {
      display: none !important;
    }
  }

  @include respond-to(medium) {
    &--m {
      display: none !important;
    }
  }

  @include respond-to(small) {
    &--s {
      display: none !important;
    }
  }

  @include respond-to(xsmall) {
    &--xs {
      display: none !important;
    }
  }
}
