@mixin font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

@mixin font-style-default {
  font-size: $font-size;
  line-height: $font-line-height;
  font-family: $font;
  color: $c-text-gray;
}
