.fnc-layout {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  padding: 0 $layout-padding;
  display: block;

  @include respond-to(small) {
    padding: 0 $layout-padding--small;
  }
  @include respond-to(xsmall) {
    padding: 0 $layout-padding--xsmall;
  }
}
