.fnc-site-header {
  padding: 30px 50px;
  margin: auto;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 99;
  transition: background .2s, box-shadow 0.3s;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;

  @include respond-to(medium) {
    padding: 20px 40px;
  }

  @include respond-to(small) {
    padding: 15px 25px;
  }

  @include respond-to(xsmall) {
    padding: 15px 15px;
  }

  &__nav {
    margin-left: auto;
    @include respond-to(xsmall) {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      height: 100vh;
      background: $c-white;
      padding: 25px;
      padding-top: 60px;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease-in-out;
      transform: translateX(110%);

      &--open {
        transform: translateX(0);
      }
    }

  }

  &__show-more {
    display: none;
    margin-left: auto;
    position: relative;
    z-index: 1;

    .fnc-icon {
      font-size: $font-size;
    }

    .fnc-site-header__open-btn {
      display: block;
    }
    .fnc-site-header__close-btn {
      display: none;
    }

    &--open {
      .fnc-site-header__open-btn {
        display: none;
      }
      .fnc-site-header__close-btn {
        display: block;
      }
    }

    @include respond-to(xsmall) {
      display: block;
    }
  }
}

.fnc-site-header--sticky {
  background: $c-white;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
}
