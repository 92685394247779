.fnc-action-group {
  display: inline-flex;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 20px;
  }

  &--block {
    width: 100%;

    & > .fnc-input-field {
      flex: 1;
    }
  }

  &--collapse {
    flex-direction: column;
    & > *:not(:last-child) {
      margin: 0;
      margin-bottom: 15px;
      width: 100%;
    }
  }

  &--collapse-m {
    @include respond-to(medium) {
      flex-direction: column;
      & > *,
      & > *:not(:last-child) {
        margin: 0;
        margin-bottom: 15px;
        width: 100%;
      }
    }
  }

  &--collapse-s {
    @include respond-to(small) {
      flex-direction: column;
      & > *,
      & > *:not(:last-child) {
        margin: 0;
        margin-bottom: 15px;
        width: 100%;
      }
    }
  }

  &--collapse-xs {
    @include respond-to(xsmall) {
      flex-direction: column;
      & > *,
      & > *:not(:last-child) {
        margin: 0;
        margin-bottom: 15px;
        width: 100%;
      }
    }
  }
}
