@import "node_modules/Gridtacular/src/gridtacular";

////
// Overwrite Gridtacular Push mixin
//
// Set the push classes that will incrementally indent
// the item by a maximum number of total-items -1
////
@mixin grid-push($fnc-prefix: null, $fnc-suffix: null, $fnc-items: null) {
  #{$fnc-prefix}reset#{$fnc-suffix} {
    margin-left: 0;
  }

  @for $fnc-i from 1 through $fnc-items - 1 {
    $fnc-css_classes: equivalent-fractions($fnc-i, $fnc-items);
    $fnc-push_class: null;

    @each $fnc-key, $fnc-css_class in $fnc-css_classes {
      $fnc-full_css_class: #{$fnc-prefix}#{$fnc-css_class}#{$fnc-suffix};
      $fnc-push_class: $fnc-push_class, $fnc-full_css_class;
    }

    #{$fnc-push_class} {
      margin-left: item-width($fnc-i, $fnc-items);
    }
  }
}

@include grid_generate($grid-settings); // sass-lint:disable-line mixin-name-format

.fnc-grid {
  &--stacked {
    margin-bottom: -#{$grid-gutter};

    > * {
      margin-bottom: $grid-gutter;
    }
  }
  &--stacked-small {
    margin-bottom: -#{$grid-gutter-small};

    > * {
      margin-bottom: $grid-gutter-small;
    }
  }
  &--stacked-x-small {
    margin-bottom: -#{$grid-gutter / 4};

    > * {
      margin-bottom: $grid-gutter / 4;
    }
  }

  &--bordered {
    & > .fnc-col {
      &:before {
        content: "";
        display: block;
        width: calc(100% + 80px);
        height: 1px;
        border-top: 1px solid $c-border;
        margin-bottom: $grid-gutter;
        margin-left: -40px;

        @include respond-to(small) {
          margin-bottom: $grid-gutter-small;
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }
}
