.fnc-list, ul {
  counter-reset: bllist;
}

.fnc-list__item, li {
  padding: 20px 40px 20px 80px;
  font-size: $font-size;

  @include respond-to(small) {
    font-size: $font-size--small;
  }

  ol & {
    position: relative;
    &:before {
      counter-increment: bllist;
      content: counter(bllist);
      text-align: center;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 20px;
      width: 35px;
      height: 35px;
      color: $c-oxley;
      background: $c-porcelain;
      font-weight: bold;
      border-radius: 50%;
      @supports((clip-path: url(#stone-shape-01))) {
        clip-path: url(#stone-shape-01);
        border-radius: 0;
      }
    }
    &:nth-child(2n) {
      &:before {
        width: 31px;
        height: 31px;
        border-radius: 50%;
        @supports((clip-path: url(#stone-shape-02))) {
          clip-path: url(#stone-shape-02);
          border-radius: 0;
        }
      }
    }
    &:nth-child(3n) {
      &:before {
        width: 31px;
        height: 31px;
        border-radius: 50%;
        @supports((clip-path: url(#stone-shape-03))) {
          clip-path: url(#stone-shape-03);
          border-radius: 0;
        }
      }
    }
  }

  .fnc-list--bordered & {
    border-bottom: 1px solid $c-border;
    &:last-child {
      border-bottom: 0;
    }
  }

  .fnc-list--naked & {
    padding-left: 0;
    background: none;
    &:before {
      display: none;
    }
  }

  .fnc-list--steps & {
    padding-left: 100px;
    &:before {
      top: 0;
      left: 0;
      transform: none;
      width: 70px;
      height: 70px;
      font-size: $font-size--large;
      background: $c-white;

      @include respond-to(small) {
        font-size: $font-size;
        width: 50px;
        height: 50px;
      }
    }
    &:nth-child(2n) {
      &:before {
        width: 62px;
        height: 62px;

        @include respond-to(small) {
          width: 53px;
          height: 53px;
        }
      }
    }
    &:nth-child(3n) {
      &:before {
        width: 62px;
        height: 62px;

        @include respond-to(small) {
          width: 50px;
          height: 50px;
        }
      }
    }
    @include respond-to(small) {
      padding-left: 70px;
    }
  }

  @include respond-to(small) {
    padding: 15px 15px 15px 70px;
    font-size: $font-size--small + 1;
  }
}
