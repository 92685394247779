.fnc-u-spacer {
  @include u-spacer;
}

.fnc-u-spacer--xsmall {
  @include u-spacer--xsmall;
}

.fnc-u-spacer--small {
  @include u-spacer--small;
}

.fnc-u-spacer--medium {
  @include u-spacer--medium;
}

.fnc-u-spacer--large {
  @include u-spacer--large;
}

.fnc-u-spacer--none {
  @include u-spacer--none;
}
