// sass-lint:disable no-important

// Standard alignment
.fnc-u-align-left {
  text-align: left !important;
}

.fnc-u-align-center {
  text-align: center !important;
}

.fnc-u-align-right {
  text-align: right !important;
}

@each $name, $align in $bp-list {
  @include respond-to(map-get($align, media)) {
    .fnc-u-align-left--#{$name} {
      text-align: left !important;
    }

    .fnc-u-align-center--#{$name} {
      text-align: center !important;
    }

    .fnc-u-align-right--#{$name} {
      text-align: right !important;
    }
  }
}

// Flex alignment
.fnc-u-flex-align-left {
  display: flex;
  align-items: flex-start !important;
}

.fnc-u-flex-align-center {
  display: flex;
  justify-content: center !important;
}

.fnc-u-flex-align-right {
  display: flex;
  align-items: flex-end !important;
}

.fnc-u-flex-space-between {
  display: flex;
  justify-content: space-between !important;
}

.fnc-u-flex-space-around {
  display: flex;
  justify-content: space-around !important;
}

.fnc-u-flex-v-top {
  display: flex;
  justify-content: flex-start !important;
}

.fnc-u-flex-v-center {
  display: flex;
  justify-content: center !important;
}

.fnc-u-flex-v-bottom {
  display: flex;
  justify-content: flex-end !important;
}

.fnc-u-flex-v-stretch {
  display: flex;
  justify-content: stretch !important;
}
