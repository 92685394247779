.fnc-input-field {
  padding: 21px 48px;
  border-radius: 35px;
  font-size: $font-size;
  font-family: $font-primary;
  color: $c-text;
  border: 0;
  box-shadow: 0 6px 28px 0 rgba(0, 0, 0, 0.08);

  &::placeholder {
    color: lighten($c-text, 40%);
  }

  @include respond-to(small) {
    padding: 18px 38px;
  }
}

.fnc-input-field--block {
  display: block;
  width: 100%;
}
