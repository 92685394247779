.fnc-hightlight-wrapper {
  position: relative;
  width: 100%;
  &:before {
    content: '';
    height: 70px;
    width: 12px;
    background: $c-finc;
    position: absolute;
    top: 0;
    left: 102px;
  }
  & > * {
    background: $c-bg;
    padding: 55px 60px;
    padding-top: 80px;
  }

  @include respond-to(small) {
    &:before {
      height: 45px;
      width: 10px;
      left: 80px;
    }
    & > * {
      padding: 40px 40px;
      padding-top: 60px;
    }
  }
}
