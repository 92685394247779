.fnc-line {
  background-color: $c-finc;
  height: 85px;

  &:before {
    content: '';
    background-color: $c-finc;
    display: block;
    width: 100vw;
    height: 100%;
    position: absolute;
    left: calc(50% + 20px);
    right: 0;
    margin-left: -50vw;
    margin-right: -50vw;
    top: 0;
  }

  @include respond-to(medium) {
    height: 65px;
  }

  @include respond-to(small) {
    background: $c-finc;
    height: 38px;
    position: absolute;
    left: 50%;
    right: 0;
    margin-left: -50vw;
    margin-right: -50vw;
    bottom: 22px;
  }
}
