// @import url('https://fonts.googleapis.com/css?family=Ubuntu:300,300i,400,400i,500,500i,700,700i&display=swap');

/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Volte';
  font-style: italic;
  font-weight: normal;
  src: local('Volte Regular Italic'), url('fonts/volte-regular-italic.woff') format('woff');
}


@font-face {
  font-family: 'Volte';
  font-style: normal;
  font-weight: normal;
  src: local('Volte Regular'), url('fonts/volte.woff') format('woff');
}


@font-face {
  font-family: 'Volte';
  font-style: italic;
  font-weight: 300;
  src: local('Volte Light Italic'), url('fonts/volte-light-italic.woff') format('woff');
}


@font-face {
  font-family: 'Volte';
  font-style: normal;
  font-weight: 300;
  src: local('Volte Light'), url('fonts/volte-light.woff') format('woff');
}


@font-face {
  font-family: 'Volte';
  font-style: italic;
  font-weight: 500;
  src: local('Volte Medium Italic'), url('fonts/volte-medium-italic.woff') format('woff');
}


@font-face {
  font-family: 'Volte';
  font-style: normal;
  font-weight: 500;
  src: local('Volte Medium'), url('fonts/volte-medium.woff') format('woff');
}


@font-face {
  font-family: 'Volte';
  font-style: italic;
  font-weight: 600;
  src: local('Volte Semibold Italic'), url('fonts/volte-semibold-italic.woff') format('woff');
}


@font-face {
  font-family: 'Volte';
  font-style: normal;
  font-weight: 600;
  src: local('Volte Semibold'), url('fonts/volte-semibold.woff') format('woff');
}


@font-face {
  font-family: 'Volte';
  font-style: italic;
  font-weight: bold;
  src: local('Volte Bold Italic'), url('fonts/volte-bold-italic.woff') format('woff');
}


@font-face {
  font-family: 'Volte Bold';
  font-style: normal;
  font-weight: bold;
  src: local('Volte Bold'), url('fonts/volte-bold.woff') format('woff');
}


$font-primary: 'Volte', sans-serif;
$font-heading: 'Volte', sans-serif;

$font-size: 18px !default;
$font-line-height: 1.6 !default;
$font-size--x-small: 14px !default;
$font-size--small: 15px !default;
$font-size--large: 24px !default;

$font-paragraphs: (
  large-bold: (
    size: 20px,
    space: 0,
    line-height: 1.33,
    letter-spacing: 0,
    font-weight: 700
  ),
  large-medium: (
    size: 20px,
    space: 0,
    line-height: 1.33,
    letter-spacing: 0,
    font-weight: 600
  ),
  large-regular: (
    size: 20px,
    space: 0,
    line-height: 1.33,
    letter-spacing: 0,
    font-weight: 400
  ),
  default-bold: (
    size: 16px,
    space: 0,
    line-height: 1.6,
    letter-spacing: 0,
    font-weight: 700
  ),
  default-medium: (
    size: 16px,
    space: 0,
    line-height: 1.6,
    letter-spacing: 0,
    font-weight: 600
  ),
  default-regular: (
    size: 16px,
    space: 0,
    line-height: 1.6,
    letter-spacing: 0,
    font-weight: 400
  ),
  small-bold: (
    size: 12px,
    space: 0,
    line-height: 1.5,
    letter-spacing: 0,
    font-weight: 700
  ),
  small-medium: (
    size: 12px,
    space: 0,
    line-height: 1.5,
    letter-spacing: 0,
    font-weight: 600
  ),
  small-regular: (
    size: 12px,
    space: 0,
    line-height: 1.5,
    letter-spacing: 0,
    font-weight: 400
  ),
  quote: (
    size: 22px,
    space: 0,
    line-height: 1.41,
    letter-spacing: 0,
    font-weight: 700
  )
) !default;

$font-titles: (
  1: (
    size: 68px,
    sizeL: 60px,
    sizeM: 50px,
    sizeS: 38px,
    sizeXXS: 34px,
    weight: 600,
    space: 0.5em,
    line-height: 1.03,
  ),
  2: (
    size: 56px,
    sizeL: 48px,
    sizeM: 40px,
    sizeS: 30px,
    sizeXXS: 28px,
    weight: 600,
    space: 0.7em,
    letter-spacing: normal,
    line-height: 1.03,
  ),
  3: (
    size: 34px,
    sizeL: 32px,
    sizeM: 28px,
    sizeS: 22px,
    sizeXXS: 20px,
    weight: 600,
    space: 0.5em,
    line-height: normal,
  ),
  4: (
    size: 26px,
    sizeS: 20px,
    weight: 600,
    space: 0.5em,
    letter-spacing: normal
  ),
  5: (
    size: 22px,
    sizeS: 18px,
    weight: 600,
    space: 0.4em,
    line-height: 1.3,
    letter-spacing: normal
  ),
  6: (
    size: 14px,
    sizeS: 14px,
    weight: 600,
    line-height: normal,
    letter-spacing: normal
  )
) !default;
