.fnc-button {
  background-color: $c-text-dark;
  color: $c-white;
  font-weight: 500;
  padding: 16px 24px;
  font-size: $font-size + 1;
  font-family: $font-heading;
  border-radius: $layout-border-radius;
  transition: background-color .1s;
  text-align: center;

  @include respond-to(small) {
    padding: 14px 45px;
    font-size: $font-size--small + 1;
  }

  &:hover, &:focus {
    text-decoration: none;
    background-color: lighten($c-text-dark, 7%);
  }

  &:hover:focus {
    background-color: lighten($c-text-dark, 20%);
  }
}

.fnc-button--secondary {
  background-color: $c-action;
  color: $c-white;

  &:hover, &:focus {
    background-color: $c-action-hover;
  }

  &:hover:focus {
    background-color: $c-action-hover-focus;
  }
}

.fnc-button--block {
  display: block;
  width: 100%;
}

.fnc-button--rounded {
  border-radius: 35px;
}

.fnc-button--naked {
  background: transparent;
  padding: 0;
  color: $c-text;

  &:hover,
  &:focus,
  &:hover:focus {
    background: transparent;
  }
}
