.fnc-pager {
  color: $c-text;
  font-size: $font-size;
  font-family: $font-heading;
  & > * {
    margin: 0 5px;
  }
  &__count {
    margin-right: 20px;
    em {
      font-weight: bold;
    }
  }

  &__prev, &__next, &__page {
    text-decoration: underline;
    color: $c-action;
    &:hover {
      text-decoration: none;
    }
  }
}
