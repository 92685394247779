.fnc-lang-switch {
  position: relative;
  &__toggle {
    @include respond-to(xsmall) {
      display: none;
    }
  }

  &__list {
    background: $c-vanhalla;
    width: 180px;
    display: block;
    position: absolute;
    top: 28px;
    right: 0;

    &--hidden {
      display: none;
      @include respond-to(xsmall) {
        display: block;
      }
    }

    @include respond-to(xsmall) {
      background: transparent;
      position: relative;
    }
  }

  &__list-item {
    padding: 0;
    background: none;
  }

  &__lang {
    color: $c-white;
    padding: 10px 25px;
    display: block;
    position: relative;
    font-family: $font-heading;

    &--active {
      &:after {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        background-color: $c-action;
      }
    }

    &:hover {
      background:  $c-bright-gray;
    }

    @include respond-to(xsmall) {
      color: $c-text;
      text-align: center;
      padding: 0px;
      margin-bottom: 25px;
      &--active {
        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          bottom: -25px;
          left: 0;
          right: 0;
          margin: auto;
          background-color: $c-action;
        }
      }
      &:hover {
        background: transparent;
      }
    }
  }
}
