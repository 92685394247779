@mixin u-spacer {
  margin-bottom: 20px;
}

@mixin u-spacer--xsmall {
  margin-bottom: 10px;
}

@mixin u-spacer--small {
  margin-bottom: 15px;
}

@mixin u-spacer--medium {
  margin-bottom: 30px;
}

@mixin u-spacer--large {
  margin-bottom: 60px;
}

@mixin u-spacer--none {
  margin-bottom: 0;
}
