.fnc-site-footer {
  padding: 60px 60px;
  max-width: 1900px;
  margin: auto;
  font-size: $font-size--small;

  @include respond-to(medium) {
    padding: 50px 75px;
  }

  @include respond-to(small) {
    padding: 40px 30px;
  }

  @include respond-to(xsmall) {
    padding: 30px 20px;
  }
}
