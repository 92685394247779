.fnc-title {
  color: $c-text-dark;
  @each $level, $size-info in $font-titles {
    &--h#{$level} {
      @include title($level);
      font-family: $font-heading;
    }
  }
}

.fnc-title--discrete {
  font-size: 22px;
  margin-bottom: 1.5em;
  color: $c-finc;

  @include respond-to(medium) {
    font-size: 20px;
    margin-bottom: 1.2em;
  }

  @include respond-to(small) {
    font-size: 16px;
  }
}

.fnc-title--no-space {
  margin-bottom: 0;
}

.fnc-title--discrete-alt {
  @extend .fnc-title--discrete;
  color: $c-text;
}

.fnc-title--center {
  text-align: center;
}
