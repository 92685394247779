@mixin title($tagStyle: "h1", $variant: "bold") {
  @each $size, $size-info in $font-titles {
    @if $tagStyle == $size {
      line-height: map-get($size-info, line-height);
      margin-bottom: map-get($size-info, space);
      font-weight: map-get($size-info, weight);
      font-size: map-get($size-info, size);
      letter-spacing: map-get($size-info, letter-spacing);

      @include respond-to(large) {
        font-size: map-get($size-info, sizeL);
      }

      @include respond-to(medium) {
        font-size: map-get($size-info, sizeM);
      }

      @include respond-to(small) {
        font-size: map-get($size-info, sizeS);
      }

      @include respond-to(xsmall) {
        font-size: map-get($size-info, sizeXS);
      }

      @include respond-to(xxsmall) {
        font-size: map-get($size-info, sizeXXS);
      }
    }
  }
}

@mixin title-ratio($size) {
  font-size: $size;

  @include respond-to(large) {
    font-size: font-size-ratio('l', $size);
  }

  @include respond-to(medium) {
    font-size: font-size-ratio('m', $size);
  }

  @include respond-to(small) {
    font-size: font-size-ratio('s', $size);
  }

  @include respond-to(xsmall) {
    font-size: font-size-ratio('xs', $size);
  }

  @include respond-to(xxsmall) {
    font-size: font-size-ratio('xxs', $size);
  }
}
