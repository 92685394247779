.fnc-region {
  margin: 0 auto;
  padding: 110px 0;
  position: relative;

  @include respond-to(medium) {
    padding: 80px 0;
  }

  @include respond-to(small) {
    padding: 50px 0;
  }

  &--condensed {
    padding: 50px 0;

    @include respond-to(medium) {
      padding: 40px 0;
    }

    @include respond-to(xsmall) {
      padding: 30px 0;
    }
  }
}

.fnc-region--full-height {
  height: 100vh;
  min-height: 1000px;
  max-height: 1300px;

  @include respond-to(xlarge) {
    min-height: 850px;
    max-height: 1150px;
  }

  @include respond-to(large) {
    min-height: 760px;
    max-height: 850px;
  }

  @include respond-to(medium) {
    min-height: 680px;
    max-height: 720px;
  }

  @include respond-to(small) {
    min-height: 600px;
    max-height: 650px;
  }

  @include respond-to(xsmall) {
    min-height: 600px;
    max-height: 650px;
  }

  @include respond-to(xxsmall) {
    min-height: 560px;
    max-height: 680px;
  }
}

.fnc-region--intro {
  display: flex;
  align-items: center;
  flex: 0 1 auto;

  & > .fnc-grid {
    margin-top: -75px;

    @include respond-to(medium) {
      margin-top: -20px;
    }

    @include respond-to(small) {
      margin-top: 20px;
    }

    @include respond-to(xxsmall) {
      margin-top: 0px;
    }
  }

  &:before {
    content: '';
    background-color: #8CB8D5;
    background-image: radial-gradient(circle at 0% 8%, #ffffff, rgba(255, 255, 255, 0.9) 20%, rgba(255, 255, 255, 0) 76%);
    display: block;
    width: 100vw;
    max-width: inherit;
    height: 100%;
    position: absolute;
    left: 50%;
    right: 0;
    margin-left: -50vw;
    margin-right: -50vw;
    top: 0;
  }
  &::after {
    content: '';
    background-image: url(images/modern-1-min.png);
    background-size: 250px;
    background-position: bottom right;
    background-repeat: no-repeat;
    display: block;
    width: 100vw;
    height: 100%;
    position: absolute;
    left: 50%;
    right: 0;
    margin-left: -50vw;
    margin-right: -50vw;
    top: 0;
  }

  @include respond-to(xsmallup) {
    align-items: flex-start;
    &::after {
      background-size: 300px;
    }
  }

  @include respond-to(smallup) {
    &::after {
      background-size: 450px;
    }
  }

  @include respond-to(mediumup) {
    align-items: center;
    &::after {
      background-size: 700px;
    }
  }

  @include respond-to(largeup) {
    align-items: center;
    &::before {
      background-image: radial-gradient(circle at 10% 6%, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.85) 23%, rgba(255, 255, 255, 0) 70%);
    }
    &::after {
      background-size: 900px;
    }
  }

  @include respond-to(xlargeup) {
    &::after {
      background-size: 1150px;
    }
  }

  @include respond-to(xxlargeup) {
    &::after {
      background-size: 1550px;
    }
  }
}

.fnc-region--alt {
  background-color: $c-alabaster;
  &:before {
    content: '';
    background-color: $c-alabaster;
    display: block;
    width: 100vw;
    max-width: inherit;
    height: 100%;
    position: absolute;
    left: 50%;
    right: 0;
    margin-left: -50vw;
    margin-right: -50vw;
    top: 0;
  }
}

.fnc-region--triangle {
  margin-bottom: 50px;
  &:after {
    content: '';
    display: block;
     height: 194px;
     width: 100%;
     background: url(images/regio-arrow-down.svg);
     background-repeat: no-repeat;
     background-position: center center;
     background-size: contain;
     position: absolute;
     bottom: -160px;
     left: 0;
     right: 0;
     z-index: -1;
  }

  @include respond-to(medium) {
    margin-bottom: 60px;
    &:after {
      width: 100%;
      height: 150px;
      bottom: -120px;
      left: 0;
      right: 0;
    }
  }

  @include respond-to(small) {
    margin-bottom: 60px;
    &:after {
      width: 100%;
      height: 100px;
      bottom: -80px;
      left: 0;
      right: 0;
    }
  }
}

.fnc-region--no-padding-top {
  padding-top: 0;
}

.fnc-region--no-padding-bottom {
  padding-bottom: 0;
}

.fnc-region--background {
  padding-top: 720px;
  padding-bottom: 0;
  &:before {
    content: '';
    background: url(images/app-screenshot.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
    display: block;
    width: 115%;
    max-width: 100vw;
    height: 719px;
    position: absolute;
    left: -7.5%;
    right: 0;
    top: 0;
    margin: auto;
  }

  @include respond-to(small) {
    padding-top: 245px;
    &:before {
      width: 100vw;
      position: absolute;
      height: 300px;
      left: 50%;
      right: 0;
      margin-left: -50vw;
      margin-right: -50vw;
    }
  }
}

.fnc-region--robot {
  &::before {
    content: "";
    background: url(images/human-robot.svg);
  }
}
