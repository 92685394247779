.fnc-meer-weten {
  position: absolute;
  bottom: 10px;
  left: 0;
  z-index: 1;
  transform: rotate(-90deg);
  transform-origin: top left;
  color: $c-text-dark;
  font-weight: 600;
}
