.fnc-annotation {
  font-family: $font-primary;
  font-size: $font-size--small;
  color: $c-gray;

  & + .fnc-annotation {
    margin-left: 10px;
  }
}

.fnc-annotation--dark {
  color: $c-text;
}

.fnc-annotation--block {
  display: block;

  & + .fnc-annotation {
    margin-left: 0;
  }
}

.fnc-annotation--bold {
  font-weight: bold;
}

.fnc-annotation--marked {
  background: $c-iceberg;
  padding: 1px 3px 1px 3px;
}
