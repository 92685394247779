.fnc-time-table {
  margin: 20px 0 40px 0;
  thead {
    th {
      position: relative;
      &:before,
      &:after {
        content: '';
        height: 1px;
        width: 100%;
        border-bottom: 1px solid $c-border;
        background: $c-border;
        display: block;
        position: absolute;
        left: 0;
      }
      &:before {
        bottom: 3px;
      }
      &:after {
        bottom: -1px;
      }
    }
  }

  tbody {
    th {
      position: relative;
      text-align: right;
      text-transform: uppercase;
      &:before,
      &:after {
        content: '';
        width: 1px;
        height: calc(100% + 60px);
        border-bottom: 1px solid $c-border;
        background: $c-border;
        display: block;
        position: absolute;
        top: -30px;
      }
      &:before {
        right: 3px;
      }
      &:after {
        right: -1px;
      }
    }
    tr {
      border-top: 1px solid $c-border;
      &:last-child {
        border-bottom: 1px solid $c-border;
      }
      &.fnc-time-table__row--active {
        background: $c-porcelain;
      }
    }
  }

  th, td {
    padding: 30px 35px;
    &:not(:last-child) {
      border-right: 1px solid $c-border;
    }
  }
}
