.fnc-intro {
  font-size: 28px;
  font-family: $font-primary;
  color: $c-text-dark;
  font-weight: 500;
  margin: 25px 0;
  margin-top: 10px;

  @include respond-to(small) {
    font-size: 17px;
  }

  @include respond-to(xxsmall) {
    font-size: 16px;
  }
}
