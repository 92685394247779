.fnc-icon {
  line-height: inherit;
  font-size: $font-size--small;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.fnc-icon--large {
  font-size: $font-size;
}

.fnc-icon--before {
  margin-right: 5px;
}

.fnc-icon--after {
  margin-left: 5px;
}

.fnc-icon--alt {
  color: $c-san-marino;
}
