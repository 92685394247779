.fnc-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .fnc-card {
    max-width: 230px;
    flex-grow: 1;
    margin: 10px 10px;
  }

  @include respond-to(small) {
    .fnc-card {
      max-width: 200px;
    }
  }
}
