.fnc-typography p,
.p {
  margin-bottom: 1.5em;

  &:last-child {
    margin-bottom: 0;
  }
}

.fnc-typography + .fnc-typography {
  margin-top: 1.5em;
}

.fnc-typography {
  font-size: $font-size;
  line-height: $font-line-height;
  font-family: $font-primary;
  color: $c-text;

  @include respond-to(small) {
    font-size: $font-size--small;
  }

  &--large {
    font-size: $font-size--large;

    @include respond-to(small) {
      font-size: $font-size;
    }
  }

  strong,m
  b {
    font-weight: 600;
  }

  i:not(.fa):not(.far):not(.fab) { // except for i used for icons
    font-style: italic;
  }

  em {
    background: $c-iceberg;
    padding: 1px 3px 3px 3px;
  }

  strike,
  s {
    text-decoration: line-through;
  }

  a {
    text-decoration: none;
    color: $c-action-text;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: $font-heading;
  }

  h1 {
    @include title(1);
    margin-top: 60px;
  }
  h2 {
    @include title(2);
    margin-top: 50px;
  }
  h3 {
    @include title(3);
    margin-top: 40px;
  }
  h4 {
    @include title(4);
    margin-top: 40px;
  }
  h5 {
    @include title(5);
    margin-top: 30px;
  }
  h6 {
    @include title(6);
    margin-top: 20px;
  }

  blockquote {
    margin-bottom: 1.7em;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 900px;
    width: 120%;

    @include respond-to(medium) {
      width: 100%;
      left: 0;
      transform: translateX(0);
    }
  }

  table {
    @extend .fnc-table;
    margin-bottom: 4em;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 900px;
    width: 120%;

    @include respond-to(medium) {
      width: 100%;
      left: 0;
      transform: translateX(0);
    }
  }

  img {
    margin-bottom: 1.5em;
    width: 100%;
  }
}

.fnc-typography--light {
  color: $c-bg;
}

.fnc-typography--center {
  text-align: center;
}
