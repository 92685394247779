.fnc-card {
  background: $c-white;
  border-radius: 16px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
  display: block;
  z-index: 9;
}

.fnc-card__content-wrapper {
  padding: 60px 50px;

  @include respond-to(small) {
    padding: 25px 30px;
  }
}

.fnc-card__content {
  width: 100%;
}

.fnc-card__icon {
  margin-bottom: 20px;
  .fnc-icon {
    font-size: 50px;
  }
  color: $c-finc;
  @include respond-to(small) {
    margin-bottom: 20px;
    .fnc-icon {
      font-size: 38px;
    }
  }
}

.fnc-card--condensed {
  text-align: center;
  width: 300px;
  font-size: $font-size;
  color: $c-text-dark;
  line-height: 1.3;
  .fnc-card__content-wrapper {
    padding: 60px 40px;
  }
  .fnc-card__icon {
    .fnc-icon {
      font-size: 38px;
    }
  }

  @include respond-to(small) {
    font-size: $font-size--small;

    .fnc-card__content-wrapper {
      padding: 40px 30px;
    }
    .fnc-card__icon {
      .fnc-icon {
        font-size: 32px;
      }
    }
  }
}

.fnc-card--full-height {
  min-height: 100%;
}

.fnc-card--alt {
  background: $c-black-haze;
  box-shadow: none;

  .fnc-card__icon {
    color: $c-finc;
  }
}
